import React, { useEffect, useRef, useState } from "react";
import {  EventCard, NewChampionsCard, NewContentV2, NewVideoPlayer, RecommendedSurveyCard } from "../../../MainEvents/RecommendEventVideos/styles";
import { ImageUrl } from "../../../../utils/constants";
// import moment from "moment";
import { t } from "i18next";
import PropTypes from 'prop-types';
import { ChallengeHeader, ChallengeHeadermain, SeeAllButton } from "../../../ChallengeDashboardV2/styles";
// import Thumbnail from "../../../Thumbnail";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getSurveyDetails } from "../../../../redux/actions";
import { fetchApi, getPermissionStatus } from "../../../../utils/methods";
import { FetchRecommendedSurveysV2 } from "../../../../redux/constants/apiConstants";
import { toast } from "react-toastify";
import Spinner from "../../../common/Spinner";
import LazyImage from "../../../common/LazyImage/LazyImage";
import SkeletonLoder from "../../../common/skeletonLoder";
// import { RecommendedSurveyCard } from "./style";

const RecommendedSurveys = (props) => {
  const{history, userPermissions,setActiveTab}=props;
  const[recommendedSurverys, setRecommendedSurverys]=useState([]);
  const [onHover, setOnHover]=useState({});
  const launchSurveyLibrary = getPermissionStatus("Launch survey library", userPermissions);
  const componentMounted = useRef(true); // (3) compo
  const[isLoading, setIsLoading] = useState(false);
  const[isReady, setIsReady]= useState(false);


  useEffect(() => {
    getSurveys();

    return () => { // This code runs when component is unmounted
      componentMounted.current = false; // (4) set it to false when we leave the page
    }
  }, []);

  
  const getSurveys = async() => {
    setIsLoading(true);
    try {
      const res = await fetchApi(FetchRecommendedSurveysV2,"GET");

      if(res.data.message){
        toast.error(res.data.message)
      }else{
        setRecommendedSurverys(res.data);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error)
    }
  };


  const onEnterMouse = (id) => {
    setOnHover(id)
  }

  const onLeaveMouse = () => {
    setOnHover(-1)
  };

  const UseTemplate = async (surveyId) => {
    setIsReady(true);
    history.push({ pathname: "/company/surveys/launch-custom-survey", state: { surveyId } });
  };

  return (
    <React.Fragment>
      {isLoading ? <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
        {Array.from({ length: 3 }).map((_, index) => (
          <SkeletonLoder key={index} width={"395px"} height={"180px"} style={{margin:"0px 20px 20px 0px"}}/>
        ))}
      </div> :recommendedSurverys.length > 0  && (<React.Fragment> 
        <ChallengeHeadermain>
          <div>
            <ChallengeHeader color={1} style={{marginBottom:'20px',color:"rgba(0, 92, 135, 1)"}}>{t("Recommended Surveys to launch")}</ChallengeHeader>
          </div>
          <div>
            {/* {recommendedSurverys && recommendedSurverys.length > 2 ? <SeeAllButton onClick={() => setShowMore(true)} color={"color"}>{showMore ? t('See Less <') : t  ('See All >')}</SeeAllButton> : ''} */}
            {recommendedSurverys && recommendedSurverys.length > 2 ? <SeeAllButton onClick={() =>{setActiveTab && setActiveTab(3);history.push("/company/surveys/library");}} style={{color: "rgba(0, 92, 135, 0.60)",fontFamily: "Rubik",fontSize: "18px",fontWeight: 400,lineHeight: "24px"}}>{ t('See All >')}</SeeAllButton> : ''}
          </div>
        </ChallengeHeadermain>

        <RecommendedSurveyCard style={{ display: recommendedSurverys.length > 0  && "flex",marginBottom:"20px" }}>
          {recommendedSurverys &&
          recommendedSurverys.length > 0  ? 
            recommendedSurverys.map((survey, index) => (

              <NewChampionsCard key={index} grid={0} style={{width:"399px"}}>
                <EventCard videoCSS={0} onMouseEnter={() => onEnterMouse(survey.id)} onMouseLeave={() => onLeaveMouse()} style={{height:'auto', margin:'0px'}}>
                  <NewVideoPlayer>
                    <LazyImage
                      src={`${ImageUrl}/${survey.image}`}
                      alt={survey.survey_point}
                      fallbackImage={ImageUrl + "/admin-survey/survey_backdrop.png"}
                    />
                    {/* <img                     src={`${ImageUrl}/${survey.image}`}
                    alt={survey.survey_name}
                    style={{width:'295px', height:'190px', borderRadius:'6px 6px 0px 0px'}}/> */}
                  </NewVideoPlayer>
                  {onHover === survey.id && (
                    <div className="educateImageBackground" style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                      <div className="" style={{background:'white', padding:'9px',borderRadius:'3px', display:'flex', alignItems:'center', justifyContent:'center',cursor:'pointer', width:"200px", marginBottom:"15px", color:"#005C87", lineHeight:"20px", fontFamily:"Rubik-Medium", fontSize:"18px"}} 
                        onClick={()=>history.push({pathname:`/company/survey-preview/${survey.id}`,state:{type:"woliba", companyId:survey.company_id}})}>Preview </div>
                      {launchSurveyLibrary && (
                        <div
                          className="getStarted"
                          style={{ background: "#005C87", width:"200px", borderRadius:'3px',}}
                          onClick={() =>
                            !isReady && UseTemplate(survey.id)
                            
                          }
                        >
                          {isReady ? <Spinner color={"white"}/> : "Use Template"}
                        </div>
                      )}
                    </div>
                  )}
                  <NewContentV2 newPadding={'newPadding'} style={{minHeight:'54px', }}>
                    <div className='firstContainer' style={{display:'flex', justifyContent:'space-between', width:'100%', alignItems:"center"}}>
                      <div className='name' title={survey?.title} style={{fontSize:'18px',fontWeight:'500',lineHeight:'24px', fontFamily:"Rubik-Medium", width:"72%",color:"rgba(0, 92, 135, 1)"}}>
                        {survey?.title}
                      </div>
                      <div className="quiz" style={{color:"rgba(0, 92, 135, 0.60)", lineHeight:'20px',fontSize:'16px'}}>
                        {survey?.library_questions_count} Questions
                      </div>
                    </div>
                  </NewContentV2>
                </EventCard>
              </NewChampionsCard>)):   
            <div>No data found</div> }      
          {/* <NoDataCard title={"There is not workout in the library you need to add workout?"} btnText={"Create Workout"} btnOnclick={() => props.history.push("/education/create-workout")}/> */}
        
        </RecommendedSurveyCard></React.Fragment>)}
    </React.Fragment>
  );
};

RecommendedSurveys.propTypes = {
  history: PropTypes.object,
  getSurveyDetails: PropTypes.func.isRequired,
  userPermissions: PropTypes.array,
  role: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func
};

const mapStateToProps = (state) => ({
  isLoading: state.surveys.isLoading,
  userPermissions: state.profileData.userPermissions
});

const mapDispatchToProps = (dispatch) => ({
  getSurveyDetails: (surveyId, history, route) => dispatch(getSurveyDetails(surveyId, history, route)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecommendedSurveys));
