import React, { useEffect, useRef, useState, useMemo } from "react";
import PropTypes from 'prop-types';
import RecommendedSurveys from "./RecommendedSurveys";
import SurveyAnalysis from "./Analysis";
import NoDataCard from "../../common/NoDataCard";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ActiveSurveys from "./ActiveSurvey";
import { FetchActiveSurveys, FetchSurveyAnalysis } from "../../../redux/constants/apiConstants";
import { toast } from "react-toastify";
import CustomSurveyPopUp from "../SurveyLibrary/CustomSurveyPopUp";
import NoActiveSurvey from "./ActiveSurvey/NoActiveSurvey";
import { fetchApi } from "../../../utils/methods";

const SurveyDashboard = (props) => {
  const { selectedYearInfo, header, setHeader, surveyReportDetails, surveyId, user, createCustomSurveyPermission, companyInfo, role,setActiveTab } = props;

  const [SurveyAnalysisData, setSurveyAnalysisData] = useState([]);
  const [activeSurveys, setActiveSurveys] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [isCustomSurvey, setisCustomSurvey] = useState(false);
  const componentMounted = useRef(true);
  const [isActiveSurveyLoading, setIsActiveSurveyLoading] = useState(false);
  const [isSurveyAnalysisLoading, setIsSurveyAnalysisLoading] = useState(false);

  useEffect(() => {
    getSurveyAnalysis();
    getActiveSurveys();

    return () => {
      componentMounted.current = false;
    }
  }, []);

  const getSurveyAnalysis = async() => {
    setIsSurveyAnalysisLoading(true);
    try {
      const res = await fetchApi(FetchSurveyAnalysis + companyInfo.id,"GET");

      if(res?.data?.message){
        toast.error(res.data.message)
      }else{
        setSurveyAnalysisData(res.data);
        setIsSurveyAnalysisLoading(false);
      }
    } catch (error) {
      toast.error(error);
      setIsSurveyAnalysisLoading(false);
    }
  };

  const getActiveSurveys = async() => {
    setIsActiveSurveyLoading(true);
    try {
      const res = await fetchApi(FetchActiveSurveys + companyInfo.id,"GET");

      if(res?.data?.message){
        toast.error(res.data.message)
      }else{
        setActiveSurveys(res.data);
        setIsActiveSurveyLoading(false);
      }
    } catch (error) {
      toast.error(error)
    }
  };

  const title = useMemo(() => (
    <>
      Hey <span style={{ fontWeight: "500", fontFamily: "Rubik-Medium" }}>{companyInfo?.name}</span>, Your Company doesn’t have anything to show in My Survey. Let’s start creating a survey from your survey library.
    </>
  ), [user?.fname]);

  return (
    <>
      <div>
        <RecommendedSurveys role={role} style={{marginBottom:"60px"}} setActiveTab={setActiveTab}/>
      </div>
      {(!SurveyAnalysisData?.length && !activeSurveys?.length) ? (
        <NoDataCard
          btnColor="#005C87"
          title={title}
          showButton={createCustomSurveyPermission}
          btnText="Create Custom Survey"
          imagePath="/admin-survey/no_survey_graphics.png"
          style={{ marginBottom: "80px" }}
          btnOnclick={() => setisCustomSurvey(true)}
        />
      ) : (
        <>
          <div>
            {activeSurveys.length ? (
              <ActiveSurveys activeSurveys={activeSurveys} isActiveSurveyLoading={isActiveSurveyLoading} setActiveTab={setActiveTab}/>
            ) : (
              <NoActiveSurvey name={companyInfo?.name} role={role} setisCustomSurvey={setisCustomSurvey} setActiveTab={setActiveTab}/>
            )}
          </div>

          <div style={{display:"none"}}>
            <SurveyAnalysis
              SurveyAnalysis={SurveyAnalysisData}
              surveyReportDetails={surveyReportDetails}
              selectedYearInfo={selectedYearInfo}
              header={header}
              setHeader={setHeader}
              surveyId={surveyId}
              isSurveyAnalysisLoading={isSurveyAnalysisLoading}
            />
          </div>
        </>
      )}

      {isCustomSurvey && <CustomSurveyPopUp showModal={isCustomSurvey} onClose={() => setisCustomSurvey(false)} setActiveTab={setActiveTab}/>}
    </>
  );
};

SurveyDashboard.propTypes = {
  history: PropTypes.object,
  selectedYearInfo: PropTypes.number,
  setHeader: PropTypes.func,
  header: PropTypes.string,
  surveyReportDetails: PropTypes.array,
  surveyId: PropTypes.number,
  user: PropTypes.object,
  createCustomSurveyPermission: PropTypes.bool,
  companyInfo: PropTypes.object,
  role: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func,
};

const mapStateToProps = (state) => ({
  biometricDetails: state.peopleHome.biometricDetails,
  user: state.profileData.user,
});

export default withRouter(connect(mapStateToProps, null)(SurveyDashboard));
